<template>
  <!-- 申请人类型 -->
  <div class="applicant box-shadow-default">
    <!-- 申请类型 -->
    <div class="applicant-type">
      <h3>选择申请类型</h3>
      <el-cascader-panel ref="cascader" :options="options" :props="props" @change="handleItemChange" />
    </div>
    <!-- 底部按钮 -->
    <div class="applicant-button text-center">
      <el-button plain @click="$router.back()">上一步</el-button>
      <el-button type="primary" :disabled="isClickRegister" @click="goGuidance">办事指南</el-button>
      <el-button type="primary" :disabled="isClickRegister" @click="preHandleRegister">在线登记</el-button>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { localDataUser } from '@iebdc/utils/local-data';
import applicantTypeService from '@/pages/iebdc/api/apply/applicant-type';
import applicantSaveService from '@/pages/iebdc/api/apply/applicant-save';
export default {
  name: 'applicantType',
  computed: {
    // 下一步跳转所需数据
    ...mapState('wysq-step', ['nextItem']),
    // 选择的流程类型信息
    ...mapState('wysq-data', ['firstFlow']),
    // 过滤权限后的流程数据
    ...mapState('wysq-step', ['stepList']),
    // 选择的机构信息
    getSelectedOrgInfo() {
      return this.$store.getters['dashboard/getSelectedOrgInfo'];
    },
  },
  data() {
    let _this = this;
    return {
      props: {
        value: 'id',
        label: 'name',
        children: 'flowDefConfigs',
        lazy: true,
        // 级联选择器动态加载数据方法
        async lazyLoad(node, resolve) {
          // 获取当前节点val、层级
          const { value, level } = node;
          // 一级类接口需要参数
          const firstLevelParams = {
            djjg: _this.getSelectedOrgInfo.djjg,
            bdclb: value,
          };
          // 动态加载节点数据
          if (level === 1) {
            // 获取下一节点数据
            const { data, success, message } = await applicantTypeService.getFlowDefConfig(firstLevelParams);
            if (success) {
              resolve(data || []);
            } else {
              _this.$message.error(message);
            }
          }
          // 三级类接口所需参数
          const thirdLevelParams = {
            parentId: value,
            djjg: _this.getSelectedOrgInfo.djjg,
            flowType: localDataUser.get().userCategory || '',
          };
          // 获取三级流程
          if (level === 3) {
            const { data, success, message } = await applicantTypeService.getThirdFlow(thirdLevelParams);
            if (success) {
              if (data.page.length) {
                // 下一级有数据
                data.page.forEach((item) => (item.leaf = true));
                resolve(data.page);
              } else {
                // 下一级无数据
                const noDataTip = [
                  {
                    id: 'no',
                    name: '流程未开放',
                    leaf: true,
                  },
                ];
                resolve(noDataTip);
              }
            } else {
              this.$message.error(message);
            }
          }
          resolve(node);
        },
      },
      // 级联选择类型
      options: [
        { id: 'HOUSE', name: '房屋' },
        { id: 'LAND', name: '土地' },
        { id: 'WOODLAND', name: '林木' },
      ],
      // 在线登记是否能点击
      isClickRegister: true,
    };
  },
  methods: {
    // 保存一级流程数据方法
    ...mapMutations('wysq-data', ['setFirstFlow']),
    // 处理选择节点变化
    handleItemChange(val) {
      // 选择的最后一个节点
      const lastNode = val[val.length - 1];
      if (lastNode !== 'no') {
        // 登记类型可以点击
        this.isClickRegister = false;
        this.handleFlowData();
        return;
      }
      // 登记类型不可点击
      this.isClickRegister = true;
    },
    // 保存选择的流程信息(先执行过滤)
    handleFlowData() {
      // 选中的节点数据
      const selectData = this.$refs.cascader.getCheckedNodes()[0].pathNodes;
      // 需要赋值的流程key
      const flowKey = ['firstTypeData', 'secondTypeData', 'thirdTypeData', 'fourTypeData'];
      // 保存流程数据
      if (selectData.length > 1) {
        const flowData = flowKey.reduce((acc, cur, index) => {
          acc[cur] = selectData[index].data;
          return acc;
        }, {});
        // 设置流程数据
        this.setFirstFlow({ ...flowData });
      }
    },
    // 预处理跳转
    preHandleRegister() {
      //当前选择的流程code
      const currentSelectedFlowCode = this.firstFlow.thirdTypeData.netFlowdefCode;
      // console.log(currentSelectedFlowCode, 'currentSelectedFlowCode');
      //找到code值属于产权核验步骤/提取楼盘表步骤，并渲染相应的步骤条DOM
      //this.$store.commit('wysq-step/getStepList', currentSelectedFlowCode);
      this.$store.commit('wysq-step/getStepList', {
        code: currentSelectedFlowCode,
      });
      //解决步骤条多了一步，nextItem没有改变问题
      this.$store.commit('wysq-step/targetToStep', '申请类型');
      // console.log(this.nextItem, 'nextItem');
      // this.$router.push({
      //   name: this.nextItem.name,
      // });
      // 选择进入产权核验步骤/提取楼盘表步骤
      //this.toRegister();
      // 如果有产权核验数组,并且当前流程code在配置的里面
      if (this.nextItem.title !== '填写申请表') {
        this.$router.push({
          name: this.nextItem.name,
        });
      } else {
        // 不走产权核验
        this.toRegister();
      }
    },
    // 在线登记, 处理需要保存给后台的数据
    async toRegister() {
      /**
       * params 需要提交给后台的数据
       * localDataUser 用户信息
       * firstFlow 选择的申请类型数据
       * firstFlow.secondTypeData 选择的申请类型二级类
       * firstFlow.thirdTypeData 选择的申请类型三级类
       * firstFlow.fourTypeData 选择的申请类型四级类
       * */
      const params = {
        sfyddbl: 0,
        userName: localDataUser.get().realName,
        lcdm: this.firstFlow.thirdTypeData.netFlowdefCode,
        userId: localDataUser.get().id, // 用户id
        sqrlx: localDataUser.get().userCategory, // 申请人类型
        bdclb: this.firstFlow.secondTypeData.bdclbEnum, // 不动产类别
        flowcode: this.firstFlow.thirdTypeData.flowCode,
        netFlowdefCode: this.firstFlow.thirdTypeData.netFlowdefCode,
        flowname: this.firstFlow.thirdTypeData.name,
        djdl: this.firstFlow.thirdTypeData.djdl,
        qllx: this.firstFlow.thirdTypeData.qllx,
        subFlowcode: this.firstFlow.fourTypeData.code,
        subFlowname: this.firstFlow.fourTypeData.name,
        taskCode: this.firstFlow.thirdTypeData.taskCode,
        pjlcmc: this.firstFlow.thirdTypeData.pjlcmc,
        taskHandleItem: this.firstFlow.thirdTypeData.taskHandleItem,
        qlxxExMhList: [],
        ...this.getSelectedOrgInfo,
      };
      const { data, success, message } = await applicantSaveService.saveSqxx(params);
      if (success) {
        // 路由跳转
        // await this.$router.push({
        //   name: 'applicantTable',
        //   params: { ywh: data.wwywh }
        // });
        await this.$router.push({
          name: this.nextItem.name,
          params: { ywh: data.wwywh },
        });
      } else {
        this.$message.error(message);
      }
    },
    goGuidance() {
      console.log('流程数据---', this.firstFlow);
      const params = {
        activeType: 'guidance',
        id: this.firstFlow.fourTypeData.id,
      };
      this.$emit('changeComponent', { ...params });
    },
  },
};
</script>

<style scoped lang="scss">
@import 'src/styles/common-variables';
.applicant {
  padding-bottom: $spacing-large;
  /*申请类型*/
  .applicant-type {
    padding: 20px;
    background-color: $background-color-layout;
    h3 {
      padding: 18px 5px;
      color: rgba(51, 51, 51, 1);
      font-weight: bold;
    }
    /deep/ .el-cascader-menu__wrap {
      min-height: 500px;
    }
  }
  /* 申请按钮 */
  .applicant-button {
    margin-top: $spacing-large;
    /deep/ .el-button + .el-button {
      margin-left: 28px;
    }
  }
}
</style>
